<template>
    <div>
        <nuxt-resource lazy class="logo mb-2" src="images/icons/trustpilot/trustpilot-logo.svg" alt="Trustpilot logo" />
        <TrustpilotStars class="review-star-large" :star-rating="rating"></TrustpilotStars>
        <div class="score mt-2">
            <strong class="rating">{{ decimalRating }}</strong>
            <span class="grey">/ 5</span>
            <UIIcon name="circle" class="separator grey small" />
            <strong>{{ total }}</strong>
            <span>{{ translate('reviews') }}</span>
        </div>
        <transition-group name="reviews" tag="div">
            <div v-for="review in productReviews" :key="review.id" class="review">
                <div class="review-heading grey small">
                    <span>{{ review.consumer.displayName }}</span>
                    <span>{{ formatDate(review.createdAt) }}</span>
                </div>
                <TrustpilotStars :star-rating="review.stars" class="review-star"></TrustpilotStars>
                <span>{{ review.content }}</span>
            </div>
        </transition-group>
        <div v-if="productReviews.length === 0" class="no-reviews">{{ translate('noReviews') }}</div>
        <div v-if="showLoadMore" class="load-more review" @click="loadMore">{{ translate('loadMore') }}</div>
        <div v-if="productReviews.length < 5 && productReviews.length !== total" class="load-more review" @click="showAllReviews">
            {{ translate('showAll') }}
        </div>
    </div>
</template>
<script>
import { UIIcon } from '@dundle/ui/components';
import TrustpilotStars from '~/components/layout/sidebar/TrustpilotStars';
import TranslationMixin from '~/mixins/TranslationMixin';

export default {
    components: {
        TrustpilotStars,
        UIIcon,
    },
    mixins: [TranslationMixin],
    props: {
        productReviews: {
            required: true,
            type: Array,
        },
        total: {
            required: true,
            type: Number,
        },
        rating: {
            required: true,
            type: Number,
        },
        links: {
            required: true,
            type: Array,
        },
    },
    data() {
        return {
            page: 1,
            hasLocale: this.productReviews.length < 5 && this.productReviews.length !== this.total,
        };
    },
    computed: {
        showLoadMore() {
            if (this.links.length && this.links[0].rel === 'next-page') {
                return true;
            }
            return false;
        },
        decimalRating() {
            return this.rating.toFixed(1);
        },
    },
    methods: {
        formatDate(dateString) {
            const date = new Date(dateString);
            const formatted = date.toLocaleDateString(this.$locale.iso, {
                month: 'long',
                day: 'numeric',
            });
            return formatted;
        },
        loadMore() {
            this.page += 1;
            this.$emit('loadMore', { page: this.page, hasLocale: this.hasLocale, clearReviews: false });
        },
        showAllReviews() {
            this.$emit('loadMore', { page: this.page, hasLocale: this.hasLocale, clearReviews: true });
        },
    },
};
</script>

<style lang="scss" scoped>
@use 'sass:math';

img.logo.mb-2 {
    width: 7rem;
    height: math.div(74, 300) * 7rem;
}

.separator {
    margin: 0 10px 0 10px;
    width: 0.4em;
}

.score {
    color: $color-grey-7;
}

.review {
    margin-top: 0.8rem;
    border-top: 1px solid $color-grey-4;
    color: $color-grey-7;
}

.review span {
    padding-right: 0.7rem;
}

.reviews-enter-active,
.reviews-leave-active {
    transition: height 0.5s ease-in-out, opacity 0.5s ease-in-out;
    overflow: hidden;
}
.reviews-enter,
.reviews-leave-to {
    height: 0;
    opacity: 0;
}

.review-heading {
    display: flex;
    justify-content: space-between;
    margin: 0.5rem 0.7rem 0 0;
}

.grey {
    color: $color-grey-5;
}

.load-more {
    color: $color-trustpilot-green;
    cursor: pointer;
    padding: 1.5rem 0 0 0;
    text-align: center;
}

.rating {
    font-size: 1.3em;
}

.no-reviews {
    text-align: center;
    margin: 1em 0 1em 0;
}
</style>

<i18n src="@dundle/locale/data/trustpilot.json"></i18n>
