<template>
    <AdminContentTitle v-if="cmsIsEnabled" :content="content" />
    <component :is="tag" v-else v-html="processedContent"></component>
</template>
<script>
import { mapGetters } from 'vuex';
import TranslationMixin from '~/mixins/TranslationMixin';

export default {
    components: {
        AdminContentTitle: () => import('~/components/admin/AdminContentTitle'),
    },
    mixins: [TranslationMixin],
    props: {
        tag: {
            type: String,
            default: 'h1',
        },

        content: {
            required: true,
            default() {
                return {
                    value: '',
                };
            },
        },

        params: {
            type: Object,
        },
    },
    computed: {
        ...mapGetters({
            cmsIsEnabled: 'cms/isEnabled',
        }),
        processedContent() {
            return this.translateParams(this.content.value, this.params);
        },
    },
};
</script>
