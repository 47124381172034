<template>
    <UIRipple class="x-product-item pt-3 pb-3" color="black" :opacity="0" @click.native="changeRegion">
        <div class="d-md-flex">
            <div class="d-flex align-items-center flex-grow-1">
                <div class="fixed-width mr-3">
                    <ProductImage :product="product" :width="110"></ProductImage>
                </div>

                <ProductDetails :product="product" class="flex-grow-1"></ProductDetails>
            </div>

            <hr class="d-block d-sm-none w-100" />

            <div class="button-container d-flex justify-content-end align-items-center">
                <UIButton variant="default" class="region-button">
                    <UIFlag class="mr-2" :code="region" />
                    Dundle {{ region.toUpperCase() }}
                </UIButton>
            </div>
        </div>
    </UIRipple>
</template>

<script>
import { UIButton, UIRipple, UIFlag } from '@dundle/ui/components';
import { ProductImage, ProductDetails } from '~/components/product';
import TranslationMixin from '~/mixins/TranslationMixin';

export default {
    components: {
        UIRipple,
        UIButton,
        UIFlag,
        ProductImage,
        ProductDetails,
    },

    mixins: [TranslationMixin],

    props: {
        brand: {
            type: Object,
            required: true,
        },

        region: {
            type: String,
            required: true,
            default: 'us',
        },

        language: {
            default: 'en',
        },
    },

    computed: {
        product() {
            return {
                ...this.brand.products[0],
                brand: {
                    slug: this.brand.slug,
                    name: this.brand.name,
                    productName: {
                        value: null,
                    },
                },
            };
        },
    },

    methods: {
        changeRegion() {
            const url = this.$locale.url({
                name: 'brand',
                params: {
                    brand: this.brand.slug,
                    slug: null,
                },
                iso: this.language + '-' + this.region,
                deriveDomain: true,
            });
            window.open(url, '_self');
        },
    },
};
</script>

<style lang="scss" scoped>
.x-product-item {
    display: block;
    background-color: #fff;
    cursor: pointer;
    border-bottom: 1px solid $color-grey-3;

    @media screen and (max-width: 767px) {
        padding: 0 15px;

        .touch-UIRipple {
            display: none;
        }

        &:not(:first-child) {
            margin-top: 10px;
        }

        &:not(:last-child) {
            margin-bottom: 10px;
        }
    }

    &:hover {
        background-color: $color-primary-light;
    }

    .fixed-width {
        width: 110px;
        position: relative;
    }

    &:hover,
    &:active {
        text-decoration: none;
    }

    .button-container,
    .button-container button {
        min-width: 150px;
    }

    .region-button {
        color: $color-primary-dark !important;
        font-weight: 700;
    }
}
</style>

<i18n src="@dundle/locale/data/keywords.json"></i18n>
<i18n src="@dundle/locale/data/buttons.json"></i18n>
<i18n src="@dundle/locale/data/countries.json"></i18n>
<i18n src="@dundle/locale/data/cart.json"></i18n>
