<template></template>

<script>
import TranslationMixin from '~/mixins/TranslationMixin';

export default {
    mixins: [TranslationMixin],

    props: {
        brand: {
            required: true,
        },

        products: {
            required: true,
        },

        reviews: {
            required: true,
        },
    },

    computed: {
        isImageTest() {
            return this.$locale.region === 'ca' && this.brand.slug === 'amazon';
        },

        description() {
            const { description } = this.brand.details ? this.brand.details : { description: '' };

            return this.translateParams(description);
        },

        gtin13() {
            return this.findFirstPropertyInProducts('ean13');
        },

        lowPrice() {
            return Math.min(...this.products.map(product => product.faceValue));
        },

        highPrice() {
            return Math.max(...this.products.map(product => product.faceValue));
        },

        currency() {
            if (Array.isArray(this.products) && this.products.length > 0) {
                return this.products[0].currencyCode.toUpperCase();
            } else {
                // No products available, default to current currency:
                return this.$locale.currency;
            }
        },

        images() {
            const root = this.$locale.url('index', 'en-us');

            return [`${root}resources/images/products/product-google-search/${this.brand.slug}-1x1.png`, `${root}resources/images/products/product-google-search/${this.brand.slug}-4x3.png`, `${root}resources/images/products/product-google-search/${this.brand.slug}-16x9.png`];
        },

        structuredData() {
            return {
                '@context': 'http://schema.org',
                '@type': 'Product',
                gtin13: this.gtin13,
                image: this.images,
                name: this.$brand.getName(this.brand),
                description: this.description,
                sku: this.brand.slug,
                brand: {
                    '@type': 'Brand',
                    name: this.brand.name,
                },

                offers: this.structuredDataOffers,

                aggregateRating: this.structuredDataAggregateRating,

                review: this.structuredDataReview,
            };
        },

        structuredDataOffers() {
            const root = this.$locale.url('index', 'en-us');

            if (Array.isArray(this.products) && this.products.length > 0) {
                return this.products.map(product => ({
                    '@type': 'Offer',
                    itemOffered: this.$product.getName(product),
                    price: Number(this.$product.getPrice(product) * this.$currency.exchangeTo(product.currencyCode)).toFixed(2),
                    priceCurrency: product.currencyCode.toUpperCase(),
                    availability: 'https://schema.org/InStock',
                    url: this.$locale.url(this.$brand.getRoute(product.brand)),
                    image: this.isImageTest ? `${root}resources/images/products/product-google-search/${product.faceValue}-cad-amazon-16x9.png` : undefined,
                }));
            }
            return undefined;
        },

        structuredDataAggregateRating() {
            // Check whether there are any reviews:
            if (typeof this.reviews === 'object' && this.reviews.count > 0) {
                return {
                    '@type': 'AggregateRating',
                    ratingValue: `${this.reviews.rating.toFixed(1)}`,
                    reviewCount: `${this.reviews.count}`,
                    bestRating: 5,
                    worstRating: 1,
                };
            }
            return undefined;
        },

        structuredDataReview() {
            // Check whether there are any product reviews (in chosen language) available:
            if (typeof this.reviews === 'object' && Array.isArray(this.reviews.productReviews) && this.reviews.productReviews.length > 0) {
                return [
                    ...this.reviews.productReviews.map(review => ({
                        '@type': 'Review',
                        reviewBody: review.content,
                        author: {
                            '@type': 'Person',
                            name: review.consumer.displayName,
                        },
                        datePublished: review.createdAt,
                        reviewRating: {
                            '@type': 'Rating',
                            ratingValue: '' + review.stars,
                        },
                    })),
                ];
            }
            return undefined;
        },
    },

    methods: {
        findFirstPropertyInProducts(propName) {
            return this.brand.products.map(product => product[propName]).find(propValue => typeof propValue !== 'undefined' && propValue !== null);
        },
    },

    head() {
        return {
            __dangerouslyDisableSanitizers: ['script'],
            script: [
                {
                    type: 'application/ld+json',
                    innerHTML: JSON.stringify(this.structuredData),
                    body: true,
                },
            ],
        };
    },
};
</script>
