import { render, staticRenderFns } from "./ThemeQuote.vue?vue&type=template&id=4bf5734d&scoped=true"
import script from "./ThemeQuote.vue?vue&type=script&lang=js"
export * from "./ThemeQuote.vue?vue&type=script&lang=js"
import style0 from "./ThemeQuote.vue?vue&type=style&index=0&id=4bf5734d&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4bf5734d",
  null
  
)

/* custom blocks */
import block0 from "@dundle/locale/data/countries.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fbuilds%2Fkorsit-bv%2Fdundle%2Fservice%2Fdundle.com%2Fcomponents%2Ftheme%2FThemeQuote.vue&external"
if (typeof block0 === 'function') block0(component)
import block1 from "./ThemeQuote.vue?vue&type=custom&index=1&blockType=i18n"
if (typeof block1 === 'function') block1(component)

export default component.exports