<template>
    <UIGridContainer>
        <UIGridRow>
            <UIGridCol cols="12" sm="12" md="8" lg="8">
                <!-- Testing introtext only on/for DESKTOP, so the trustpilot reviews will be above fold
                            paysafecard
                            Region: DE, NL, FR
                            ISO: de-de, de-en, nl-nl, nl-en, fr-fr, fr-en -->
                <ServiceNotification v-if="notifications.length" class="mb-3" :notifications="notifications" />

                <div
                    v-if="
                        $brand.isPaysafecard(brand) &&
                        ($locale.regio === 'DE' || $locale.regio === 'NL' || $locale.regio === 'FR')
                    "
                    class="d-none d-md-block"
                >
                    <div v-if="$locale.language === 'de'" class="mb-4">
                        Mit einer paysafecard kaufen Sie einen Code, den Sie sofort als Online Zahlungsmittel verwenden
                        können. So bezahlen Sie sicher und risikofrei im Internet.
                    </div>
                    <div v-if="$locale.language === 'en'" class="mb-4">
                        Use a paysafecard to pay easily and safely on thousands of sites. Works instantly and ensures
                        optimal protection of your private details and banking information.
                    </div>
                    <div v-if="$locale.language === 'fr'" class="mb-4">
                        Avec une recharge paysafecard, payez en ligne de manière sûre et anonyme comme avec des espèces.
                        Utilisez-la directement pour payer facilement sur des milliers de sites.
                    </div>
                    <div v-if="$locale.language === 'nl'" class="mb-4">
                        Met een paysafecard betaal je gemakkelijk en veilig op duizenden sites. Werkt direct en zorgt
                        voor optimale bescherming van je privé- en bankgegevens.
                    </div>
                </div>

                <BrandPaysafeDistributor v-if="$brand.isPaysafecard(brand)" class="d-block d-md-none" />

                <!-- alternativeBrands 'logic' -->
                <div v-if="alternativeBrands">
                    <ProductAlternativeBrandProduct
                        :alternative-brands="alternativeBrands"
                        :current-brand="brand"
                        :is-moved-product="alternativeBrands.type === 'movedProduct'"
                        :message="false"
                    />
                </div>

                <div v-else-if="isAstroPayBlockedRegion" class="header-margin">
                    <UIAlert variant="warning" :icon="true" class="mt-1 mt-md-3 mb-0 mb-md-3">
                        {{
                            translate('astropayWarning', {
                                currency: $locale.currency,
                                country: translate('country.' + $locale.region.toUpperCase()),
                            })
                        }}
                    </UIAlert>
                </div>

                <div v-else-if="isNeosurfBlockedRegion" class="header-margin">
                    <UIAlert variant="warning" :icon="true" class="mt-1 mt-md-3 mb-0 mb-md-3">
                        {{
                            translate('neosurfWarning', {
                                currency: $locale.currency,
                                country: translate('country.' + $locale.region.toUpperCase()),
                            })
                        }}
                    </UIAlert>
                    <h2 v-if="neosurfAlternativeRegions && neosurfAlternativeRegions.length > 0">
                        {{ translate('neosurfHeader') }}
                    </h2>

                    <div class="product-wrapper">
                        <ProductOtherRegion
                            v-for="(region, index) in neosurfAlternativeRegions"
                            :key="index"
                            :region="region.region"
                            :language="region.language"
                            :brand="brand"
                        />
                    </div>
                </div>

                <div v-else>
                    <div class="product-wrapper">
                        <ProductItem v-for="(product, key) in products" :key="key" :product="product" :lazy="false" />
                    </div>
                </div>

                <FeatureContainer class="d-md-none" :show-product-reviews="false" />

                <section class="brand-desc mt-0 pr-md-4 d-flex flex-column justify-content-center">
                    <ContentText :content="brand.content" />

                    <div v-if="faq.length">
                        <h2>{{ faqTitle }}</h2>
                        <UIAccordion :items="faq">
                            <template #header="{ item }">
                                {{ item.name }}
                            </template>
                            <template #default="{ item }">
                                <ContentText :content="{ value: item.answer }" />
                            </template>
                        </UIAccordion>
                    </div>

                    <UIButton
                        v-if="showFaqButton"
                        class="w-75 mx-auto faq-button my-3"
                        :to="getFaqLink"
                        variant="secondary"
                    >
                        {{
                            translate('faqButton', {
                                brandName: brand.name,
                                categoryName: translate(`category.${brand.category}.name`),
                            })
                        }}
                    </UIButton>
                </section>

                <ThemeQuote />
            </UIGridCol>

            <UIGridCol cols="12" sm="12" md="4" lg="4">
                <BrandPaysafeDistributor v-if="$brand.isPaysafecard(brand)" class="mb-3 d-none d-md-block" />
                <FeatureContainer class="feature-container pt-md-0" :show-product-reviews="showProductReviews">
                    <template slot="reviews">
                        <div id="trustpilot-reviews">
                            <TrustpilotProductReviews
                                :links="brandReviews.links"
                                :product-reviews="brandReviews.productReviews"
                                :rating="brandReviews.rating"
                                :total="brandReviews.count"
                                @loadMore="loadMoreReviews"
                            />
                        </div>
                    </template>
                </FeatureContainer>
                <LoyaltyImage />
                <div v-if="articles.length">
                    <h2 class="mt-0">
                        {{ translate('relatedArticles') }}
                    </h2>
                    <BrandRelatedArticles v-for="article in articles" :key="article.id" :article="article" />
                </div>
            </UIGridCol>
        </UIGridRow>
    </UIGridContainer>
</template>

<script>
import { UIGridCol, UIGridRow, UIGridContainer, UIButton, UIAccordion, UIAlert } from '@dundle/ui/components';
import { BrandPaysafeDistributor, BrandRelatedArticles } from '~/components/brand';
import BreadcrumbMixin from '~/mixins/BreadcrumbMixin';
import TranslationMixin from '~/mixins/TranslationMixin';
import { LoyaltyImage } from '~/components/loyalty';
import { ContentText } from '~/components/content';
import { ProductItem, ProductOtherRegion } from '~/components/product';
import { ThemeQuote } from '~/components/theme';
import FeatureContainer from '~/components/feature/FeatureContainer';
import { ServiceNotification } from '~/components/service';

import TrustpilotProductReviews from '~/components/trustpilot/TrustpilotProductReviews';

export default {
    components: {
        UIAlert,
        UIAccordion,
        ThemeQuote,
        UIButton,
        UIGridCol,
        UIGridRow,
        UIGridContainer,
        ContentText,
        TrustpilotProductReviews,
        FeatureContainer,
        ProductItem,
        ProductOtherRegion,
        LoyaltyImage,
        BrandRelatedArticles,
        BrandPaysafeDistributor,
        ServiceNotification,
        ProductAlternativeBrandProduct: () => import('~/components/product/ProductAlternativeBrandProduct'),
    },
    mixins: [BreadcrumbMixin, TranslationMixin],
    props: [
        'trustpilotSkus',
        'reviews',
        'brand',
        'faq',
        'products',
        'alternativeBrands',
        'articles',
        'faqTitle',
        'notifications',
    ],
    data() {
        return {
            brandReviews: this.reviews,
        };
    },
    computed: {
        isNeosurfBlockedRegion() {
            const isBlockedRegion = [
                'de', // Germany,
                'lt', // Lithuania,
                'us', // United States of America
            ].includes(this.$locale.region);

            return this.brand.slug === 'neosurf' && isBlockedRegion;
        },
        isAstroPayBlockedRegion() {
            const isBlockedRegion = [
                'al', // Albania
                'bf', // Burkina Faso
                'gi', // Gibraltar
                'jo', // Jordan
                'ma', // Morocco
                'mm', // Myanmar (Burma)
                'ni', // Nicaragua
                'pa', // Panama
                'ph', // Philippines
                'sn', // Senegal
                'ug', // Uganda
            ].includes(this.$locale.region);

            return this.brand.slug === 'astropay' && isBlockedRegion;
        },
        neosurfAlternativeRegions() {
            if (this.$locale.region === 'de') {
                return [
                    { region: 'nl', language: 'de' },
                    { region: 'be', language: 'de' },
                    { region: 'at', language: 'de' },
                ];
            } else if (this.$locale.region === 'us') {
                return [
                    { region: 'nl', language: 'en' },
                    { region: 'ca', language: 'en' },
                    { region: 'au', language: 'en' },
                    { region: 'gb', language: 'en' },
                ];
            }
            return [];
        },

        showProductReviews() {
            return this.brandReviews.rating >= 4;
        },
        getFaqLink() {
            return this.$locale.path({ name: 'brand-faq', params: { brand: this.brand.slug } });
        },
        showFaqButton() {
            const FaqData = [
                {
                    language: 'de',
                    brandId: 'razer-gold',
                },
                {
                    language: 'de',
                    brandId: 'minecraft',
                },
                {
                    language: 'de',
                    brandId: 'microsoft',
                },
                {
                    language: 'de',
                    brandId: 'battlenet',
                },
                {
                    language: 'de',
                    brandId: 'steam',
                },
            ];
            return FaqData.find(item => item.brandId === this.brand.slug && item.language === this.$locale.language);
        },
    },
    methods: {
        async loadMoreReviews(options) {
            if (options.clearReviews) {
                this.brandReviews.productReviews = [];
            }
            const language = options.hasLocale ? '' : this.$locale.language;

            try {
                const reviewsResponse = await this.$api.trustpilot.getProductReviews(
                    this.trustpilotSkus,
                    language,
                    options.page
                );

                this.brandReviews.productReviews.push(...reviewsResponse.productReviews);
                this.brandReviews.links = reviewsResponse.links;
            } catch (error) {
                this.$sentry.captureException(error);
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.product-wrapper {
    border-top: 1px solid $color-grey-3;

    @media screen and (max-width: 767px) {
        background-color: $color-grey-1;
        padding: 10px 0;
        margin-left: -15px !important;
        margin-right: -15px !important;
    }
}
@media screen and (max-width: 767px) {
    .faq-button {
        width: 100% !important;
    }

    .header-margin {
        h2 {
            margin-top: 1.5rem;
        }
    }
}
</style>

<i18n src="@dundle/locale/data/categories.json"></i18n>
<i18n src="@dundle/locale/data/countries.json"></i18n>
<i18n src="@dundle/locale/data/warnings.json"></i18n>

<i18n></i18n>
