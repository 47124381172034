var render = function render(){var _vm=this,_c=_vm._self._c;return _c('UIGridContainer',[_c('UIGridRow',[_c('UIGridCol',{attrs:{"cols":"12","sm":"12","md":"8","lg":"8"}},[(_vm.notifications.length)?_c('ServiceNotification',{staticClass:"mb-3",attrs:{"notifications":_vm.notifications}}):_vm._e(),_vm._v(" "),(
                    _vm.$brand.isPaysafecard(_vm.brand) &&
                    (_vm.$locale.regio === 'DE' || _vm.$locale.regio === 'NL' || _vm.$locale.regio === 'FR')
                )?_c('div',{staticClass:"d-none d-md-block"},[(_vm.$locale.language === 'de')?_c('div',{staticClass:"mb-4"},[_vm._v("\n                    Mit einer paysafecard kaufen Sie einen Code, den Sie sofort als Online Zahlungsmittel verwenden\n                    können. So bezahlen Sie sicher und risikofrei im Internet.\n                ")]):_vm._e(),_vm._v(" "),(_vm.$locale.language === 'en')?_c('div',{staticClass:"mb-4"},[_vm._v("\n                    Use a paysafecard to pay easily and safely on thousands of sites. Works instantly and ensures\n                    optimal protection of your private details and banking information.\n                ")]):_vm._e(),_vm._v(" "),(_vm.$locale.language === 'fr')?_c('div',{staticClass:"mb-4"},[_vm._v("\n                    Avec une recharge paysafecard, payez en ligne de manière sûre et anonyme comme avec des espèces.\n                    Utilisez-la directement pour payer facilement sur des milliers de sites.\n                ")]):_vm._e(),_vm._v(" "),(_vm.$locale.language === 'nl')?_c('div',{staticClass:"mb-4"},[_vm._v("\n                    Met een paysafecard betaal je gemakkelijk en veilig op duizenden sites. Werkt direct en zorgt\n                    voor optimale bescherming van je privé- en bankgegevens.\n                ")]):_vm._e()]):_vm._e(),_vm._v(" "),(_vm.$brand.isPaysafecard(_vm.brand))?_c('BrandPaysafeDistributor',{staticClass:"d-block d-md-none"}):_vm._e(),_vm._v(" "),(_vm.alternativeBrands)?_c('div',[_c('ProductAlternativeBrandProduct',{attrs:{"alternative-brands":_vm.alternativeBrands,"current-brand":_vm.brand,"is-moved-product":_vm.alternativeBrands.type === 'movedProduct',"message":false}})],1):(_vm.isAstroPayBlockedRegion)?_c('div',{staticClass:"header-margin"},[_c('UIAlert',{staticClass:"mt-1 mt-md-3 mb-0 mb-md-3",attrs:{"variant":"warning","icon":true}},[_vm._v("\n                    "+_vm._s(_vm.translate('astropayWarning', {
                            currency: _vm.$locale.currency,
                            country: _vm.translate('country.' + _vm.$locale.region.toUpperCase()),
                        }))+"\n                ")])],1):(_vm.isNeosurfBlockedRegion)?_c('div',{staticClass:"header-margin"},[_c('UIAlert',{staticClass:"mt-1 mt-md-3 mb-0 mb-md-3",attrs:{"variant":"warning","icon":true}},[_vm._v("\n                    "+_vm._s(_vm.translate('neosurfWarning', {
                            currency: _vm.$locale.currency,
                            country: _vm.translate('country.' + _vm.$locale.region.toUpperCase()),
                        }))+"\n                ")]),_vm._v(" "),(_vm.neosurfAlternativeRegions && _vm.neosurfAlternativeRegions.length > 0)?_c('h2',[_vm._v("\n                    "+_vm._s(_vm.translate('neosurfHeader'))+"\n                ")]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"product-wrapper"},_vm._l((_vm.neosurfAlternativeRegions),function(region,index){return _c('ProductOtherRegion',{key:index,attrs:{"region":region.region,"language":region.language,"brand":_vm.brand}})}),1)],1):_c('div',[_c('div',{staticClass:"product-wrapper"},_vm._l((_vm.products),function(product,key){return _c('ProductItem',{key:key,attrs:{"product":product,"lazy":false}})}),1)]),_vm._v(" "),_c('FeatureContainer',{staticClass:"d-md-none",attrs:{"show-product-reviews":false}}),_vm._v(" "),_c('section',{staticClass:"brand-desc mt-0 pr-md-4 d-flex flex-column justify-content-center"},[_c('ContentText',{attrs:{"content":_vm.brand.content}}),_vm._v(" "),(_vm.faq.length)?_c('div',[_c('h2',[_vm._v(_vm._s(_vm.faqTitle))]),_vm._v(" "),_c('UIAccordion',{attrs:{"items":_vm.faq},scopedSlots:_vm._u([{key:"header",fn:function({ item }){return [_vm._v("\n                            "+_vm._s(item.name)+"\n                        ")]}},{key:"default",fn:function({ item }){return [_c('ContentText',{attrs:{"content":{ value: item.answer }}})]}}],null,false,349767608)})],1):_vm._e(),_vm._v(" "),(_vm.showFaqButton)?_c('UIButton',{staticClass:"w-75 mx-auto faq-button my-3",attrs:{"to":_vm.getFaqLink,"variant":"secondary"}},[_vm._v("\n                    "+_vm._s(_vm.translate('faqButton', {
                            brandName: _vm.brand.name,
                            categoryName: _vm.translate(`category.${_vm.brand.category}.name`),
                        }))+"\n                ")]):_vm._e()],1),_vm._v(" "),_c('ThemeQuote')],1),_vm._v(" "),_c('UIGridCol',{attrs:{"cols":"12","sm":"12","md":"4","lg":"4"}},[(_vm.$brand.isPaysafecard(_vm.brand))?_c('BrandPaysafeDistributor',{staticClass:"mb-3 d-none d-md-block"}):_vm._e(),_vm._v(" "),_c('FeatureContainer',{staticClass:"feature-container pt-md-0",attrs:{"show-product-reviews":_vm.showProductReviews}},[_c('template',{slot:"reviews"},[_c('div',{attrs:{"id":"trustpilot-reviews"}},[_c('TrustpilotProductReviews',{attrs:{"links":_vm.brandReviews.links,"product-reviews":_vm.brandReviews.productReviews,"rating":_vm.brandReviews.rating,"total":_vm.brandReviews.count},on:{"loadMore":_vm.loadMoreReviews}})],1)])],2),_vm._v(" "),_c('LoyaltyImage'),_vm._v(" "),(_vm.articles.length)?_c('div',[_c('h2',{staticClass:"mt-0"},[_vm._v("\n                    "+_vm._s(_vm.translate('relatedArticles'))+"\n                ")]),_vm._v(" "),_vm._l((_vm.articles),function(article){return _c('BrandRelatedArticles',{key:article.id,attrs:{"article":article}})})],2):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }