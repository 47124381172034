<template>
    <div class="x-brand-image cover" :class="sizeClass">
        <nuxt-resource
            :lazy="lazy"
            :src="$brand.getImage200(brand)"
            :srcset="`${$brand.getImage200(brand)} 200w`"
            sizes="(max-width: 960px) 200px, 340px"
            :alt="altText"
        />
        <div class="inner"></div>
        <UIRibbon :product="product" />
    </div>
</template>
<script>
import { UIRibbon } from '@dundle/ui/components';
export default {
    components: {
        UIRibbon,
    },
    props: {
        size: String,
        brand: Object,
        lazy: {
            type: Boolean,
            default: false,
        },
        discount: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        product() {
            return this.brand.products?.[0];
        },
        sizeClass() {
            return 'size-' + this.size;
        },

        valueClass() {
            return this.product.faceValue ? 'value' : '';
        },

        altText() {
            return `Card image of ${this.$brand.getName(this.brand)}`;
        },
    },
};
</script>
<style lang="scss" scoped>
.x-brand-image {
    background-size: cover !important;
    border-radius: 0.33rem;
    overflow: hidden;
    position: relative;
    border: 1px solid $color-grey-3;

    img {
        position: absolute;
        width: 100%;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
    }

    .outer {
        overflow: visible;
    }
    .inner {
        overflow: hidden;
        position: relative;
        width: 100%;
        height: 100%;
        padding-top: 70%;
        border-radius: 4px;
    }
    .discount {
        z-index: 1;
        position: absolute;
        top: -20px;
        left: -20px;
        width: 60px;
        height: 60px;
        border-radius: 50%;
        line-height: 15px;
        padding: 15px 0;
        color: white;
        font-weight: bold;
        background: $color-discount;
        background: linear-gradient(to bottom, $color-discount 0%, darken($color-discount, 10%) 100%);
        transform: rotate(-20deg);
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3), inset 0 0 2px rgba(0, 0, 0, 0.4);
        .price {
            font-size: 1rem;
        }
        .subText {
            font-size: 0.7rem;
            font-weight: normal;
        }
    }
    @media screen and (max-width: 575px) {
        .discount {
            left: -10px;
        }
    }
    &.size-lg {
        width: 141px;
        height: 98px;
        overflow: hidden;
        position: relative;
    }
    &.size-md {
        width: 120px;
        height: 84px;
        overflow: hidden;
        position: relative;

        .img-price {
            width: 60px;
            height: 80px;
            position: absolute;
            top: 0;
            right: 0;
            span {
                color: $color-text-inverted;
                display: block;
                padding: 1rem 0 0.16rem 0;
                font-weight: bold;
                line-height: 2.25rem;
                font-size: 1rem;
                position: relative;
                width: 120px;
                height: 40px;
                left: -15px;
                top: -5px;
                box-shadow: 0 0 4px rgba(0, 0, 0, 0.8);
                background-color: $color-secondary;
                transform: rotate(45deg);
            }
        }
    }

    &.size-sm {
        text-decoration: none;
        width: 100px;
        height: 70px;
        border-radius: 4px;
        position: relative;

        .img-price {
            display: block;
            width: 40px;
            height: 54px;
            position: absolute;
            top: 0;
            right: 0;
            span {
                color: $color-text-inverted;
                display: block;
                padding: 0.5rem 0 0.16rem 0;
                font-weight: bold;
                position: relative;
                font-size: 0.66rem;
                line-height: 1.75rem;
                text-align: center;
                width: 80px;
                height: 26px;
                left: -10px;
                top: -3px;
                box-shadow: 0 0 4px rgba(0, 0, 0, 0.8);
                background-color: $color-secondary;
                transform: rotate(45deg);
            }
        }
    }

    &.size-xs {
        width: 50px;
        height: 35px;
        position: relative;
        .img-price {
            position: absolute;
            top: 0;
            right: 0;
            background-color: $color-secondary;
            height: 18px;
            line-height: 15px;
            padding: 2px;
            text-align: center;
            color: $color-text-inverted;
        }
    }
    &.cover:after {
        display: none;
    }
}
</style>
