<template>
    <div v-if="isSupported" class="quote">
        <h2>{{ title }} <UIFlag :code="$locale.region" /></h2>
        <div class="content clearfix">
            <UIIcon class="icon-left" name="quote-left" />
            <p class="mt-2 mb-2">
                {{ content }}
            </p>
            <UIIcon class="icon-right float-right" name="quote-right" />
        </div>
    </div>
</template>

<script>
import { UIFlag, UIIcon } from '@dundle/ui/components';
import TranslationMixin from '~/mixins/TranslationMixin';

export default {
    components: {
        UIIcon,
        UIFlag,
    },
    mixins: [TranslationMixin],

    computed: {
        isSupported() {
            return !!this.translate(`${this.$locale.region}.title`);
        },

        title() {
            return this.translate(`${this.$locale.region}.title`, {
                websiteName: 'Dundle',
            });
        },

        content() {
            return this.translate(`${this.$locale.region}.content`, {
                websiteName: 'Dundle',
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.quote {
    .content {
        background-color: $color-grey-1;
        padding: 1rem;
        border-radius: $border-radius-default;

        .icon-left,
        .icon-right {
            font-size: 2rem;
            color: #bed0d3;
        }

        p {
            color: $color-text;
            font-style: italic;
            margin: 0;
        }
    }
}
</style>

<i18n src="@dundle/locale/data/countries.json"></i18n>
<i18n></i18n>
