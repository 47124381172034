<template>
    <component :is="modalName" :data="data"></component>
</template>

<script>
export default {
    components: {
        SeoStructuredDataFaq: () => import('~/components/seo/SeoStructuredDataFaq'),
    },
    props: ['component', 'data'],
    computed: {
        modalName() {
            return this.component;
        },
    },
};
</script>

<style></style>
